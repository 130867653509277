<template>
  <v-card>
    <v-card-title>User Overview</v-card-title>
    <v-card-text>
      <h3>User Info ({{ user.distributor.slug }})</h3>
      <p>{{ user.email }}</p>
      <p v-if="user.profile">
        {{ user.profile.name }}
      </p>
      <p>{{ user.channel_user_id }}</p>
      <p>{{ user.employee_id }}</p>
      <p>{{ user.created_at | date }}</p>
      <v-divider />
      <h3 class="mt-5">
        Channel
      </h3>
      <p>{{ user.channel.code }}</p>
    </v-card-text>
  </v-card>
</template>

<script>
import { date } from '@core/utils/filter';

export default {
  filters: { date },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
}
</script>
