<template>
  <div id="user-view">
    <v-row>
      <v-col
        cols="12"
      >
        <a @click="$router.back()">Back</a>
      </v-col>
      <v-col cols="4">
        <user-overview
          v-if="userData"
          :user="userData"
        />
      </v-col>

      <v-col cols="8">
        <v-tabs
          v-model="currentTab"
          show-arrows
          class="user-tabs"
        >
          <v-tab
            v-for="tab in tabs"
            :key="tab.icon"
            :disabled="tab.disabled"
          >
            <v-icon
              size="20"
              class="me-3"
            >
              {{ tab.icon }}
            </v-icon>
            <span>{{ tab.title }}</span>
          </v-tab>
        </v-tabs>

        <v-tabs-items
          v-if="userData"
          id="user-tabs-content"
          v-model="currentTab"
          class="mt-5 pa-1"
        >
          <v-tab-item>
            <user-order-list :user="userData" />
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import router from '@/router'
import { computed, onMounted, ref } from '@vue/composition-api'
import { mdiAlphaUBox } from '@mdi/js'
import useUserView from '../useChannelUser'
import UserOverview from './user-overview/UserOverview.vue'
import UserOrderList from './user-overview/UserOrderList.vue'

export default {
  components: { UserOverview, UserOrderList },

  setup() {
    const { loadUser, userData } = useUserView()

    const reloadUser = () => {
      userData.value = null

      return loadUser(router.currentRoute.params.id)
    }

    // UnRegister on leave

    const tabs = computed(() => {
      const options = [
        { icon: mdiAlphaUBox, title: 'OVERVIEW' },

        // { icon: mdiFileOutline, title: 'POLICIES' },
      ]

      return options
    })
    const currentTab = ref(0)

    const switchToDefaultTab = () => {
      const tab = router.currentRoute.query.tab || null
      const index = tabs.value.findIndex(t => t.title === tab)
      currentTab.value = Math.max(0, index)
    }

    onMounted(() => reloadUser().then(switchToDefaultTab))

    return {
      tabs,
      userData,
      reloadUser,

      currentTab,
    }
  },
}
</script>
