import { fetchOrders } from '@/api/order'
import { useNotifyErrors, useTableOptions } from '@/composables'
import { fetchUser } from '@api/user'
import { ref, watch } from '@vue/composition-api'

export default function useChannelUser() {
  const userData = ref(null)
  const orders = ref([])
  const options = ref({
    sortBy: ['created_at'],
    sortDesc: [true],
  })
  const loading = ref(false)
  let cachedQuery = {}

  // fetch data
  const loadUser = id => {
    userData.value = null
    loading.value = true

    return fetchUser(id)
      .then(res => {
        userData.value = res.data.data

        return res
      })
      .catch(useNotifyErrors)
      .finally(() => {
        loading.value = false
      })
  }

  /**
   * Load Order using user_id
   */
  const loadOrders = query => {
    orders.value = []
    loading.value = true

    return fetchOrders(useTableOptions(options.value, query || cachedQuery))
      .then(res => {
        cachedQuery = query
        orders.value = res.data.data.records

        return res
      })
      .catch(useNotifyErrors)
      .finally(() => {
        loading.value = false
      })
  }

  watch([options], () => {
    if (!loading.value) loadOrders(cachedQuery)
  })

  return {
    userData,
    orders,
    options,
    loading,

    loadUser,
    loadOrders,
  }
}
